import * as React from "react"
import { Link, graphql } from "gatsby"
import "../../css/_section-audit.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { BlogCard } from "../../components/resources"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/blogs/",
    label: "Blogs",
  },
]



const Blogs = ({data}) => {

  return (
    <Layout>
      <Seo title="Blogs" />
      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <div className="grid--bg--for-it-ops-3a ">
        <section className="section-title-top">
          <div className="container">
            <div className="row">
              <div className="section-col-1">
                <h1>BLOGS</h1>
              </div>
              <div className="section-col-2"></div>
            </div>
          </div>
        </section>
        <section className="section-client-cards">
          <div className="container">
            <div className="row pb-5 blog--nav">
            <div className="col-sm-2 ">
                <Link to="/blogs/" activeClassName="blog--nav-active">
                  ALL BLOGS
                </Link>
              </div>
              <div className="col-sm-2">
                <Link
                  to="/blogs/threat-alerts"
                  activeClassName="blog--nav-active"
                >
                  THREAT ALERTS
                </Link>
              </div>
              <div className="col-sm-2">
                <Link to="/blogs/know-more" activeClassName="blog--nav-active">
                  KNOW MORE
                </Link>
              </div>
              <div className="col-sm-2">
                <Link
                  to="/blogs/value-series"
                  activeClassName="blog--nav-active"
                >
                  VALUE SERIES
                </Link>
              </div>
            </div>
            <div className="row">
              <ul className="client-cards-lists">

              </ul>
            </div>
            <div className="row">
              <ul className="client-cards-lists">
                
              {data.allMdx.nodes.map(node => {
                  return (
                    <BlogCard
                      slug={node.frontmatter.slug}
                      imgSrc={node.frontmatter.clip}
                      date={node.frontmatter.date}
                      title={node.frontmatter.title}
                      content={node.frontmatter.excerpt}
                    />
                  )
                })}
              </ul>
            </div>
          </div>
        </section>
      </div>

      <div className="breadcrumbs">
        <nav className="container-fluid p-sides-med">
          <ul>
            {breadData.map(breadcrumb => (
              <li key={breadcrumb.id}>
                <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Layout>
  )
}

export const query = graphql`
    query {
      allMdx(
    filter: {frontmatter: {series: {eq: "A Vigilant Threat Alerts Series"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    nodes {
      id
      frontmatter {
        date(formatString: "MMM D, YYYY")
        excerpt
        slug
        title
        clip
      }
    }
  }
}
`

export default Blogs
